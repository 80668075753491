/*
$(document).ready(function(){
    var $gmaps = $('.gmap');
    if($gmaps.length){
        var gmapikey = $gmaps.first().data('map-api-key');
        (function() {
            var gmapi = document.createElement('script');
            gmapi.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://maps.googleapis.com/maps/api/js?key='+gmapikey+'&callback=initializeGmaps';
            gmapi.type = 'text/javascript';
            gmapi.async = 'true';
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(gmapi, s);
        })();
    }
});
*/
window.initializeGmaps = function () {
    /* Map flex content */
    var $gmaps = $('.gmap');
    if($gmaps.length){
        //var pinUrl = location.protocol+'//'+location.hostname+'/wp-content/themes/.../assets/img/map-pin.png';
        var pinUrl = "http://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png";
        // https://snazzymaps.com/
        var styles = '';
        var infoWindows = [];
        $gmaps.each(function(){
            // Affichage de la map
            var $gmap = $(this);
            var gmapContainer = $('.gmap--container', $gmap)[0];
            var $data = $('.gmap--data', $gmap);
            var mapLat = $data.data('lat');
            var mapLng = $data.data('lng');
            var mapLatLng = {lat: mapLat, lng: mapLng};
            var mapZoom = $data.data('zoom');
            var $markers = $('.gmap--marker', $gmap);
            mapOptions = {
                center: mapLatLng,
                mapTypeId: 'roadmap',
                gestureHandling: 'cooperative',
                zoomControl: true,
                zoomControlOptions: {
                    position: google.maps.ControlPosition.LEFT_BOTTOM
                },
                streetViewControl: false,
                zoom: mapZoom,
                mapTypeControl: true,
                mapTypeControlOptions: {
                    style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
                },
                fullscreenControl: true,
                fullscreenControlOptions: {
                    position: google.maps.ControlPosition.BOTTOM_RIGHT
                }
            };
            map = new google.maps.Map(gmapContainer, mapOptions);
            map.setOptions({styles: styles});
            // Affichage des pins
            $markers.each(function(){
                var $marker = $(this);
                var address = $marker.data('address');
                var pinLat = $marker.data('lat');
                var pinLng = $marker.data('lng');
                var pinLatLng = {lat: pinLat, lng: pinLng};
                var infoWindowContent = $($marker).html();
                var marker = new google.maps.Marker({
                    map: map,
                    position: pinLatLng,
                    title: address,
                    animation: google.maps.Animation.DROP,
                    //icon: pinUrl
                });
                var infowindow = new google.maps.InfoWindow({
                    content: '<div class="gmap--infowindow--onmap">'+infoWindowContent+'</div>'
                });
                marker.addListener('click', function() {
                    closeAllInfoWindows();
                    infowindow.open(map, marker);
                });
                $marker[0].addEventListener('click', function() {
                    closeAllInfoWindows();
                    infowindow.open(map, marker);
                });
                infoWindows.push(infowindow);
            });
            // Recentrage de la map si plusieurs pins
            var bounds = new google.maps.LatLngBounds();
            var pinsLatLng = [];
            $markers.each(function(){
                var $marker = $(this);
                var lat = $marker.data('lat');
                var lng = $marker.data('lng');
                pinsLatLng.push( new google.maps.LatLng(lat, lng));
            });
            for(var i = 0; i < pinsLatLng.length; i++){
                bounds.extend(pinsLatLng[i]);
            }
            if(pinsLatLng.length > 1){
                map.fitBounds(bounds);
            }
        });
        function closeAllInfoWindows() {
            for (var i=0;i<infoWindows.length;i++) {
                infoWindows[i].close();
            }
        }
    }




    function initMap( $el ) {

        // Find marker elements within map.
        var $markers = $el.find('.marker');

        // Create gerenic map.
        var mapArgs = {
            zoom        : $el.data('zoom') || 16,
            mapTypeId   : google.maps.MapTypeId.ROADMAP
        };
        var map = new google.maps.Map( $el[0], mapArgs );

        // Add markers.
        map.markers = [];
        $markers.each(function(){
            initMarker( $(this), map );
        });

        // Center map based on markers.
        centerMap( map );

        // Return map instance.
        return map;
    }
    function initMarker( $marker, map ) {

        // Get position from marker.
        var lat = $marker.data('lat');
        var lng = $marker.data('lng');
        var latLng = {
            lat: parseFloat( lat ),
            lng: parseFloat( lng )
        };

        // Create marker instance.
        var marker = new google.maps.Marker({
            position : latLng,
            map: map
        });

        // Append to reference for later use.
        map.markers.push( marker );

        // If marker contains HTML, add it to an infoWindow.
        if( $marker.html() ){

            // Create info window.
            var infowindow = new google.maps.InfoWindow({
                content: $marker.html()
            });

            // Show info window when marker is clicked.
            google.maps.event.addListener(marker, 'click', function() {
                infowindow.open( map, marker );
            });
        }
    }
    function centerMap( map ) {

        // Create map boundaries from all map markers.
        var bounds = new google.maps.LatLngBounds();
        map.markers.forEach(function( marker ){
            bounds.extend({
                lat: marker.position.lat(),
                lng: marker.position.lng()
            });
        });

        // Case: Single marker.
        if( map.markers.length == 1 ){
            map.setCenter( bounds.getCenter() );

            // Case: Multiple markers.
        } else{
            map.fitBounds( bounds );
        }
    }
    var $blkMaps = $('.blk-map');
    console.log($blkMaps);
    if($blkMaps.length){
        $blkMaps.each(function(){
            var $el = $('.acf-map', $(this));
            var blkMap = initMap($el);
        });
    }
};

