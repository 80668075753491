jQuery(document).ready(function($){
    var $fcSliders = $('.fc--layout--galerie .wrap');
    if ($fcSliders.length) {
        $fcSliders.each(function(index){
            var $sliderSlides = $('.slider', $(this));
            // var arrowsContainer = '<div class="arrows-container" />';
            // var $arrowsContainer = $(arrowsContainer);
            // $arrowsContainer.appendTo($(this));
            $sliderSlides.slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: false,
                autoplaySpeed: 3000,
                centerMode: true,
                centerPadding: 0,
                focusOnSelect: true,
                dots: false,
                prevArrow: '<button type="button" data-role="none" class="slick-prev slick-arrow" aria-label="Précédent" role="button" style=""><span><i class="fas fa-chevron-left"></i></span></button>',
                nextArrow: '<button type="button" data-role="none" class="slick-next slick-arrow" aria-label="Suivant" role="button" style=""><span><i class="fas fa-chevron-right"></i></span></button>',
                //appendArrows: $arrowsContainer,
                arrows: true,
                infinite: true,
                speed: 500,
                fade: true,
                cssEase: 'linear'
            });
            $('a', this).attr('data-fancybox','group-'+index);
        });
    }
    var $dataSlick = $('[data-slick]');
    if($dataSlick.length){
        $dataSlick.slick();
    }
});