$(document).ready(function(){
    var $dotdotdot = $('.dotdotdot');
    if($dotdotdot.length) {
        $dotdotdot.each(function(){
            $(this).dotdotdot({
                ellipsis: '\u2026 ',
                keep: null,
                tolerance: 10,
                truncate: 'word',
                watch: true
            });
        });
    }
});